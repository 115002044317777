import React, { useEffect } from "react";
import Header from "../header";

export const appendScript = async (scriptToAppend) => {
  const script = document.createElement("script");
  script.src = scriptToAppend;
  script.id = "CookieDeclaration";
  script.async = true;
  var parentGuest = document.getElementById("declaration");
  parentGuest.replaceChildren(script);
};

const CookiePolicy = () => {
  useEffect(() => {
    document.getElementById("declaration") &&
      appendScript(
        "https://consent.cookiebot.com/82e3b218-0575-4ea5-8872-9d93ae625869/cd.js"
      );
  }, []);
  return (
    <div>
      <div className="fixed-header">
        <Header />
        <div className="container">
          <div className="main-inner m-b-20">
            <h3>Circle MyWay Cookie Policy</h3>
          </div>
        </div>
      </div>
      <div className="main-content">
        <div className="container">
          <div className="tabs-body">
            <div className="tab-content">
              <div className="tab-left">
                <p>
                  This Cookie Policy sets out important information about the
                  way in which Circle Health MyWay Limited{" "}
                  <b>
                    (“Circle”, “we”, “us”{" "}
                    <span style={{ fontWeight: "500" }}>and</span> “our”)
                  </b>{" "}
                  uses cookies. Please take the time to read this Cookie Policy
                  carefully.
                </p>
                <p>
                  By clicking ‘Accept all cookies’, you are agreeing to the use
                  of all cookies on the Circle MyWay progressive web app{" "}
                  <b>(“MyWay”)</b>. However, you can click ‘Customise’ to change
                  your cookie setting and preferences before clicking accept.
                  You can also change your cookie settings and preferences at
                  any time.
                </p>
                <h3 style={{ paddingBottom: "15px" }}>
                  <b>Introduction</b>
                </h3>
                <p>
                  This Cookie Policy outlines our approach to cookies. We use
                  cookies to improve the services we offer and user experience,
                  and to analyse how the MyWay is being used.{" "}
                </p>
                <p>
                  This Cookie Policy does not cover or apply to other websites
                  or apps that Circle provides, nor does it cover or apply to
                  the websites or apps of any third parties that may be accessed
                  through MyWay. This includes the websites and apps of the
                  third parties whose services you can access through MyWay{" "}
                  <b>(“Service Providers”).</b>
                </p>
                <h3 style={{ padding: "15px 0px" }}>
                  <b>What are cookies?</b>
                </h3>
                <p>
                  A cookie is a small text file of letters and numbers that is
                  downloaded on to your computer, mobile phone, tablet or other
                  digital device <b>(“Device”)</b> when you visit a website.
                  Cookies are used by websites to remember information about you
                  such as your cookie preferences, and whether you are a new or
                  returning visitor to the website. Cookies can be either
                  temporary <b>(“Session Cookies”)</b> and only last the
                  duration of your browsing session, or persistent in that they
                  are not deleted when your web browser is closed down{" "}
                  <b>(“Persistent Cookies”).</b>
                </p>
                <h3 style={{ padding: "15px 0px" }}>
                  <b>What do we use Cookies for?</b>
                </h3>
                <p>
                  We use cookies and other similar tracking technologies for the
                  following purposes:
                  <ul style={{ paddingLeft: "25px" }}>
                    <li>
                      enabling your use of certain integrated website
                      functionalities e.g. access to our live website chat tool,
                      making payments;
                    </li>
                    <li>aggregating website usage information; and </li>
                    <li>
                      to assist us with targeting our advertising and marketing
                      efforts.
                    </li>
                    <p>
                      Some cookies are set by us directly, though we also use
                      third party cookies. These are cookies which are from a
                      website domain other than MyWay’s website domain.
                    </p>
                  </ul>
                </p>
                <h3 style={{ padding: "15px 0px" }}>
                  <b>Why types of Cookies do we use?</b>
                </h3>
                <p>
                  MyWay is scanned using a tool Cookiebot to ensure that we give
                  you the best experience on our website, analyse site usage and
                  assist in our marketing efforts. We use the following
                  categories of cookies:
                </p>

                <ul style={{ paddingLeft: "25px" }}>
                  <li>
                    Necessary: necessary cookies help make our website usable by
                    enabling basic functions like page navigation and access to
                    secure areas of the website. The website cannot function
                    properly without these cookies.
                  </li>
                  <li>
                    Preferences: preference cookies enable our website to
                    remember information that changes the way the website
                    behaves or looks, like your preferred language or the region
                    that you are in.
                  </li>
                  <li>
                    Statistics: statistic cookies help us to understand how
                    visitors interact with our website by collecting and
                    reporting information anonymously.
                  </li>

                  <li>
                    Marketing: marketing cookies are used to track our visitors
                    across our website. The intention is to display ads that are
                    relevant and engaging for the individual user.
                  </li>
                  <p>
                    We are permitted to store ‘Necessary’ cookies on your Device
                    if they are required for the operation of MyWay. For all
                    other cases, we are required by law to obtain your consent.
                  </p>
                </ul>
                <h3 style={{ padding: "15px 0px" }}>
                  <b>How to manage cookies</b>
                </h3>
                <p>
                  You can manage your cookie settings and preferences on our
                  website through this link
                  https://join.circlemyway.co.uk/cookie-policy. As previously
                  stated, you can change or withdraw your consent for the
                  different categories of cookies that we use at any time.
                </p>
                <h3 style={{ padding: "15px 0px" }}>
                  <b>Further information</b>
                </h3>
                <p>
                  We have set out below links to several web browsers’
                  information pages about managing cookie settings and
                  preferences:
                  <ul style={{ paddingLeft: "25px" }}>
                    <li>
                      <a
                        href="https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Microsoft Edge cookies information
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Internet Explorer cookies information
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://support.google.com/chrome/answer/95647?hl=en-GB"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Chrome cookies information
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://support.mozilla.org/en-US/kb/delete-browsing-search-download-history-firefox"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Firefox cookies information
                      </a>
                    </li>

                    <li>
                      Safari cookies information -
                      <a
                        href="https://support.apple.com/en-gb/HT201265"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {" "}
                        mobile devices
                      </a>{" "}
                      and{" "}
                      <a
                        href="https://support.apple.com/en-gb/guide/safari/sfri11471/mac"
                        target="_blank"
                        rel="noreferrer"
                      >
                        desktops
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.opera.com/help/tutorials/security/cookies/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Opera cookies information
                      </a>
                    </li>
                  </ul>
                </p>
                <p>
                  You can also find more information on cookies through the
                  Information Commissioner’s Office website here{" "}
                  <a
                    href="https://ico.org.uk/for-the-public/online/cookies/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {" "}
                    https://ico.org.uk/for-the-public/online/cookies/
                  </a>
                  , as well as from third parties websites such as{" "}
                  <a
                    href="https://www.allaboutcookies.org/manage-cookies"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {" "}
                    www.allaboutcookies.org/manage-cookies/.
                  </a>
                </p>
                <h3 style={{ padding: "15px 0px" }}>
                  <b>Contact us</b>
                </h3>
                <p>
                  If you have any questions or comments about our use of
                  cookies, please contact our Head of Information Governance and
                  Data Protection Officer. They can each be contacted using the
                  details below:
                </p>
                <ul style={{ paddingLeft: "25px" }}>
                  <li>
                    Email:{" "}
                    <a href="mailto:DataProtectionOfficer@circlehealthgroup.co.uk">
                      DataProtectionOfficer@circlehealthgroup.co.uk
                    </a>
                  </li>
                  <li style={{ paddingBottom: "20px" }}>
                    Post: Circle Health Group, 1st Floor, 30 Cannon Street,
                    London, EC4M 6XH
                  </li>
                </ul>
                <p style={{ paddingBottom: "30px" }}>
                  Lastly, please note that because we update this Cookie Policy
                  from time to time, we advise you to review this page for the
                  latest version. This Cookie Policy was last updated on 10
                  March 2023.{" "}
                </p>
                <h3>MyWay Cookie Declaration</h3>
                <br />
                <br />
                <div id="declaration"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CookiePolicy;
