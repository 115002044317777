import React from "react";
import "./index.css";
import Header from "../header";
import Footer from "../../home/footer";

const TermsAndConditions = () => {
  return (
    <div className="terms-wraper">
      <div className="fixed-header">
        <Header />
        <div className="container">
          <div className="main-inner m-b-20">
            <h3>Terms of use</h3>
          </div>
        </div>
      </div>
      <div className="main-content terms-content">
        <div className="container">
          <div className="tabs-body">
            <div className="tab-content">
              <div className="tab-left">
                <p>
                  By using MyWay, you agree to be bound by and comply with these
                  Terms of Use <span className="font_bold">(“Terms”)</span>. You
                  should read these carefully before use.
                </p>
                <p>
                  If you are uncertain about these Terms, you should either
                  contact us for further information by emailing
                  <a href="#"> support@circlemyway.co.uk </a> or visiting the
                  “Support centre” tile on your MyWay dashboard, or discontinue
                  using MyWay.
                </p>
                <ul className="list_unstyled">
                  <li>
                    <span className="pr-20">1.</span>
                    <span className="font_bold"> WHAT IS MYWAY</span>
                    <ul className="list_unstyled">
                      <li>
                        <span className="pr-20">1.1</span>
                        <span>
                          MyWay is a self-paid subscription service. It provides
                          users with access to a digital platform, through which
                          they can access the benefits through third party
                          service providers, including our affiliates{" "}
                          <span className="font_bold">
                            (“Service Providers”):
                          </span>
                        </span>
                        <ul className="list_unstyled">
                          <li>
                            <span className="pr-20">1.1.1</span> health
                            services, namely virtual GP consultations, online
                            physiotherapy questionnaire and self-care services.
                          </li>
                          <br />
                          <li>
                            <span className="pr-20">1.1.2</span> insurance cover
                            for the diagnosis of health conditions
                          </li>
                          <br />
                          <li>
                            <span className="pr-20">1.1.3</span>discounted
                            medical treatment and care at qualifying hospitals
                            and facilities within the UK.
                          </li>
                        </ul>
                      </li>
                      <li>
                        <span className="pr-20">1.2</span>MyWay is for the
                        exclusive, personal benefit of its users. As such, you
                        must not provide any other person with access to the
                        MyWay digital platform.
                      </li>
                      <br />
                      <li>
                       <span className="pr-20">1.3</span>The MyWay benefits, along with the Terms and Conditions of MyWay Membership, are outlined in full in the
                        &nbsp;<a href="https://join.circlemyway.co.uk/static/media/CircleMyWay_Membership%20Wording%20V18.fa330396aa1d51754880.pdf">
                        MyWay Health Plan Membership Handbook and Insurance Policy Terms and Conditions.
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <span className="pr-20">2.</span>{" "}
                    <span className="font_bold">
                      WHO WE ARE AND HOW TO CONTACT US
                    </span>
                    <ul classname="list_unstyled">
                      <li>
                        <span className="pr-20">2.1</span>
                        <span>
                          MyWay is a product provided by Circle Health MyWay
                          Limited{" "}
                        </span>
                        <span className="font_bold">
                          (“Circle”, “we”, “us” and “our”).
                        </span>
                      </li>
                      <br />
                      <li>
                        <span className="pr-20">2.2</span>
                        <span>
                          {" "}
                          Circle is registered in England and Wales under
                          company number 14186896. Our registered office is 1st
                          Floor, 30 Cannon Street London EC4M 6XH.{" "}
                        </span>
                      </li>
                      <br />
                      <li>
                        <span className="pr-20">2.3</span>
                        <span>
                          To contact us, please email{" "}
                          <a href="#">support@circlemyway.co.uk</a> or visit the
                          “Support centre” tile on your MyWay dashboard.
                        </span>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <span className="pr-20">3.</span>
                    <span className="font_bold">
                      BY USING MYWAY YOU ACCEPT THESE TERMS
                    </span>
                    <ul classname="list_unstyled">
                      <li>
                        <span className="pr-20"> 3.1 </span>
                        <span>
                          By using MyWay, you agree to be bound by and comply
                          with these Terms.
                        </span>
                      </li>
                      <br />
                      <li>
                        <span className="pr-20"> 3.2 </span> If you do not agree
                        to these Terms, you must not use MyWay.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <span className="pr-20">4. </span>
                    <span className="font_bold">OTHER APPLICABLE TERMS</span>
                    <ul classname="list_unstyled">
                      <li>
                        <span className="pr-20"> 4.1 </span>In addition to these
                        Terms, the following{" "}
                        <span className="font_bold">“Additional Terms”</span>{" "}
                        also apply to your use of MyWay:
                        <ul classname="list_unstyled">
                          <li>
                            <span className="pr-20"> 4.1.1 </span> the MyWay
                            Health Plan Membership Handbook and Insurance Policy
                            Terms and Conditions
                          </li>
                          <li>
                            <span className="pr-20"> 4.1.2 </span> the Insurance
                            Product Information Document which summarises your
                            cover;
                          </li>
                          <li>
                            <span className="pr-20"> 4.1.3 </span> our Service
                            Providers’ respective Terms of Use as referenced
                            below in clause 7.2;{" "}
                          </li>
                          <li>
                            <span className="pr-20"> 4.1.4 </span> our Privacy
                            Overview, accessible at
                            <a href="https://circlemyway.co.uk/privacypolicy">
                              {" "}
                              https://circlemyway.co.uk/privacypolicy,
                            </a>{" "}
                            which sets out important details about who may
                            collect and process your personal data when you use
                            MyWay; and{" "}
                          </li>
                          <li>
                            <span className="pr-20"> 4.1.5 </span> our Cookie
                            Policy
                            <a href="https://circlemyway.co.uk/cookie-policy">
                              {" "}
                              https://circlemyway.co.uk/cookie-policy,
                            </a>{" "}
                            which sets out important information our approach to
                            cookies.{" "}
                          </li>
                        </ul>
                      </li>
                      <li>
                        <span className="pr-20"> 4.2 </span> In the event of
                          any inconsistency or conflict between these Terms and
                          any of the Additional Terms, these Terms shall take
                          precedence. In the event of any inconsistency or
                          conflict between any of the Additional Terms, the
                          order of precedence is the order as they appear in
                          clause 4.1 above.
                        </li>
                    </ul>
                  </li>
                  <li>
                    <span className="pr-20">5. </span>
                    <span className="font_bold">
                      WE MAY MAKE CHANGES TO MYWAY AND TO OUR TERMS
                    </span>
                    <ul classname="list_unstyled">
                      <li>
                        <span className="pr-20"> 5.1 </span>We may update and/or
                        make changes to MyWay from time to time to:
                        <ul classname="list_unstyled">
                          <li>
                            {" "}
                            <span className="pr-20">5.1.1</span> improve
                            performance or security;
                          </li>
                          <li>
                            {" "}
                            <span className="pr-20">5.1.2 </span>
                            enhance functionality;{" "}
                          </li>
                          <li>
                            {" "}
                            <span className="pr-20">5.1.3</span> reflect changes
                            to our operating system
                          </li>
                          <li>
                            {" "}
                            <span className="pr-20">5.1.4</span> reflect changes
                            to the benefits we offer;
                          </li>
                          <li>
                            {" "}
                            <span className="pr-20">5.1.5 </span>
                            introduce new features;
                          </li>
                          <li>
                            {" "}
                            <span className="pr-20">5.1.5 </span>
                            introduce new features;
                          </li>
                          <li>
                            {" "}
                            <span className="pr-20">5.1.6 </span>
                            to meet our members’ needs’ and
                          </li>
                          <li>
                            <span className="pr-20"> 5.1.7</span> to meet our
                            business priorities and / or relevant requirements
                            of Service Providers.
                          </li>
                        </ul>
                      </li>
                      <li>
                        <span className="pr-20">5.2</span> We will always seek
                        to give you reasonable notice of any major changes to
                        the functionality of MyWay.
                      </li>
                      <li>
                        <span className="pr-20"> 5.3</span> We may amend these
                        Terms from time to time to reflect changes in law, best
                        practice or to deal with any updates and/or changes as{" "}
                        described in clause 5.1. These Terms were most recently
                        updated on 7th March 2023.{" "}
                      </li>
                      <li>
                        <span className="pr-20">5.4</span> Every time you wish
                        to use MyWay, please check these Terms to ensure you
                        understand the rules that apply at that time. Your use
                        of MyWay following such changes constitutes your
                        acceptance of these changes.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <span className="pr-20">6. </span>
                    <span className="font_bold">ENTIRE AGREEMENT</span>
                    <ul classname="list_unstyled">
                      <li>
                        <span className="pr-20">6.1 </span>These Terms, together
                        with those incorporated or referred to herein (including
                        the Additional Terms referred to in clause 4) constitute
                        the entire agreement between us relating to your use of
                        MyWay, and supersede any prior understandings or
                        agreements (whether oral or written) regarding the
                        subject matter.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <span className="pr-20">7.</span>{" "}
                    <span className="font_bold">
                      SERVICE PROVIDERS’ TERMS ALSO APPLY
                    </span>
                    <ul classname="list_unstyled">
                      <li>
                        <span className="pr-20"> 7.1 </span>In addition to these
                        Terms and others which are incorporated or referred to
                        herein, if you access services through our Service{" "}
                        Providers, you will also be bound by their respective
                        Terms of Use, Privacy Policies and other associated
                        policies.
                      </li>
                      <li>
                        <span className="pr-20"> 7.2 </span>Our Service
                        Providers, and links to their Terms of Use and Privacy
                        Policies are set out below:{" "}
                      </li>
                    </ul>
                  </li>
                  <div className="table-responsive">
                    <table>
                      <tr>
                        <th>Service Provider</th>
                        <th>Terms of Use </th>
                        <th>Privacy Policy</th>
                      </tr>
                      <tr>
                        <td>MediPartner</td>
                        <td>N/A</td>
                        <td>
                          <a href="https://medipartner.co.uk/medipartner-privacy-policy">
                            https://medipartner.co.uk/medipartner-privacy-policy
                        </a>
                        </td>
                      </tr>
                      <tr>
                        <td>HealthHero</td>
                        <td>
                          <a href="https://www.healthhero.com/terms-and-conditions">
                            https://www.healthhero.com/terms-and-conditions
                        </a>{" "}
                        </td>
                        <td>
                          <a href="https://www.healthhero.com/privacy-policy ">
                            https://www.healthhero.com/privacy-policy{" "}
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>Physitrack</td>
                        <td>
                          <a href="https://www.physitrack.co.uk/legal/terms">
                            https://www.physitrack.co.uk/legal/terms{" "}
                          </a>{" "}
                        </td>
                        <td>
                          <a href="https://www.physitrack.co.uk/legal/privacy">
                            https://www.physitrack.co.uk/legal/privacy
                        </a>
                        </td>
                      </tr>
                      <tr>
                        <td>Circle Health</td>
                        <td>
                          <a href="https://www.circlehealthgroup.co.uk/terms-and-conditions">
                            https://www.circlehealthgroup.co.uk/terms-and-conditions
                        </a>
                        </td>
                        <td>
                          <a href="https://www.circlehealthgroup.co.uk/privacy-notices">
                            https://www.circlehealthgroup.co.uk/privacy-notices
                        </a>{" "}
                        </td>
                      </tr>
                    </table>
                  </div>
                  <li>
                    <span className="pr-20">8.</span>{" "}
                    <span className="font_bold">
                      MYWAY IS FOR USERS IN THE UK
                    </span>
                    <ul classname="list_unstyled">
                      <li>
                        <span className="pr-20"> 8.1</span> MyWay is directed at
                        individuals residing in the UK. We do not represent that
                        the content available on or through MyWay is appropriate
                        for use in other locations.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <span className="pr-20"> 9. </span>
                    <span className="font_bold">
                      YOU MUST KEEP YOUR ACCOUNT DETAILS SAFE
                    </span>
                    <ul classname="list_unstyled">
                      <li>
                        <span className="pr-20"> 9.1 </span>You must treat your
                        MyWay log in details, user verification code, password
                        and any other piece of information you choose or are{" "}
                        allocated as part of our security procedures as
                        confidential. You must not disclose these to any third
                        party.1
                      </li>
                      <li>
                        <span className="pr-20"> 9.2</span> We have the right to
                        disable your log in details, user verification code
                        and/or password, whether chosen by you or allocated by
                        us, at any time, if in our reasonable opinion you have
                        failed to comply with any of the provisions of these
                        Terms.{" "}
                      </li>

                      <li>
                        <span className="pr-20"> 9.3 </span>If you know or
                        suspect that anyone other than you knows your log in
                        details, user verification code or password, you must
                        promptly contact us through the “Support centre” tile on
                        your MyWay dashboard.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <span className="pr-20">10.</span>{" "}
                    <span className="font_bold">
                      HOW YOU MAY USE MATERIAL ON MYWAY{" "}
                    </span>
                    <ul classname="list_unstyled">
                      <li>
                        <span className="pr-20"> 10.1</span>
                        <span>
                          Unless otherwise stated, all material on or accessed
                          through MyWay belongs to Circle. We are the owner
                          and/or the licensee of all intellectual property
                          rights in the material we publish. All such rights are
                          reserved.{" "}
                        </span>
                      </li>
                      <li>
                        <span className="pr-20"> 10.2 </span>For the avoidance
                        of doubt, any material on or accessed through Service
                        Providers or third parties’ websites, apps or resources
                        that MyWay may facilitate access to belongs to those
                        Service Providers or third parties.{" "}
                      </li>
                      <li>
                        <span className="pr-20"> 10.3 </span>
                        Subject only to clause 10.4, the contents of MyWay may
                        not be copied, reproduced, distributed, republished,
                        downloaded, displayed, posted or transmitted in any form
                        or by any means without our express written permission.
                        You may not remove any trade mark, copyright or other
                        proprietary notice.
                      </li>
                      <li>
                        <span className="pr-20"> 10.4 </span> You may only print
                        or download the contents of an individual page within
                        MyWay for the purposes of private and personal non-
                        commercial use. You may also make an electronically
                        stored, transient copy of the content for the purpose of
                        viewing it while connected to the Internet only,
                        however, you may only make one such copy of any content.
                      </li>
                      <li>
                        <span className="pr-20"> 10.5</span> You must not modify
                        any paper or digital copies of any materials you have
                        printed off or downloaded in accordance with clause 10.4{" "}
                        in any way.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <span className="pr-20"> 11.</span>{" "}
                    <span className="font_bold">
                      ACCEPTABLE USE RESTRICTIONS{" "}
                    </span>
                    <ul classname="list_unstyled">
                      <li>
                        <span className="pr-20"> 11.1</span> You must not:
                        <ul classname="list_unstyled">
                          <li>
                            <span className="pr-20"> 11.1.1 </span>
                            transfer, or provide any other person with access to
                            MyWay;
                          </li>
                          <li>
                            <span className="pr-20"> 11.1.2 </span>
                            use MyWay in any unlawful manner, for any unlawful
                            purpose, or in any manner inconsistent with these
                            Terms, or act fraudulently or maliciously, for
                            example, by hacking into or inserting malicious
                            code, such as viruses, or harmful data, into MyWay
                            or any related system;
                          </li>
                          <li>
                            <span className="pr-20"> 11.1.3 </span>
                            infringe our intellectual property rights or those
                            of any Service Provider or third party in relation
                            to your use of MyWay;
                          </li>
                          <li>
                            <span className="pr-20"> 11.1.4 </span>
                            transmit any material that is defamatory, offensive
                            or otherwise objectionable in relation to your use
                            of MyWay;
                          </li>
                          <li>
                            <span className="pr-20"> 11.1.5 </span>
                            use MyWay in a way that could damage, disable,
                            overburden, impair or compromise our systems or
                            security or interfere with others;{" "}
                          </li>
                          <li>
                            <span className="pr-20"> 11.1.6 </span> collect or
                            harvest any information or data from MyWay or any
                            related system or attempt to decipher any
                            transmissions to or from the servers running any
                            Service; and
                          </li>
                          <li>
                            <span className="pr-20"> 11.1.7 </span>
                            use MyWay in a manner that breaches any acceptable
                            use restrictions or limitations in the Additional
                            Terms.
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <span className="pr-20"> 12.</span>{" "}
                    <span className="font_bold">WARRANTY</span>
                    <ul classname="list_unstyled">
                      <li>
                        <span className="pr-20"> 12.1</span> Although we make
                        reasonable efforts to update the material on MyWay, we
                        make no representations, warranties or guarantees,{" "}
                        whether express or implied, that the material is
                        accurate, complete or up to date.
                      </li>
                      <li>
                        <span className="pr-20"> 12.2</span> Save for where
                        expressly stated otherwise, the material on our site is
                        provided for general information only. It is not
                        intended to amount to advice on which you should rely.
                        You must obtain professional or specialist advice before
                        taking, or refraining from, any action on the basis of
                        the content on MyWay.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <span className="pr-20"> 13.</span>{" "}
                    <span className="font_bold">
                      DATA PROTECTION AND PRIVACY{" "}
                    </span>
                    <ul classname="list_unstyled">
                      <li>
                        <span className="pr-20"> 13.1 </span>Under data
                        protection legislation, you are entitled to certain
                        information about who may collect and process your
                        personal data when you use MyWay, and what your legal
                        rights are in this respect. This information is recorded
                        in our Privacy Overview, accessible at{" "}
                        <a href="https://circlemyway.co.uk/privacy-policy">
                          https://circlemyway.co.uk/privacy-policy
                        </a>
                        , and in the Privacy Policies of our Service Providers.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <span className="pr-20"> 14.</span>{" "}
                    <span className="font_bold">LIABILITY</span>
                    <ul classname="list_unstyled">
                      <li>
                        <span className="pr-20"> 14.1</span> We are responsible
                        to you for foreseeable loss or damage caused by us. If
                        we fail to comply with these Terms, we are responsible
                        for loss or damage you suffer that is a foreseeable
                        result of our breaking these Terms or our failing to use
                        reasonable care and skill.
                      </li>
                      <li>
                        <span className="pr-20"> 14.2 </span>We are not
                        responsible to you for any loss or damage that is not
                        foreseeable. Loss or damage is foreseeable if either it
                        is obvious that it will happen or if, at the time you
                        accepted these Terms, both we and you knew it might
                        happen.{" "}
                      </li>
                      <li>
                        <span className="pr-20"> 14.3 </span>
                        Circle assumes no responsibility and shall not be liable
                        for any damage to, or viruses that may infect your
                        computer, equipment or other property on account of your
                        access to, use of, or browsing MyWay.
                      </li>
                      <li>
                        <span className="pr-20"> 14.4 </span>
                        Subject to clause 14.1, Circle shall not be liable for
                        any injury, loss, claim, damages, or any exemplary,
                        punitive, direct, indirect, incidental or consequential
                        damages of any kind whether based in contract, tort,
                        strict liability, or otherwise.{" "}
                      </li>
                    </ul>
                  </li>

                  <li>
                    <span className="pr-20"> 15. </span>
                    <span className="font_bold">
                      WE ARE NOT RESPONSIBLE FOR THIRD PARTIES’ WEBSITES, APPS
                      OR RESOURCES
                    </span>
                    <ul classname="list_unstyled">
                      <li>
                        <span className="pr-20"> 15.1 </span>These Terms do not
                        cover the websites, apps or resources of any third
                        parties or Service Providers which we may facilitate
                        access to. Circle has no control over the content of any
                        such websites, apps or resources.
                      </li>
                      <li>
                        <span className="pr-20"> 15.2 </span>Third parties or
                        Service Providers may collect or allow other third
                        parties to collect and process your personal data.
                        Circle makes no representations nor gives any
                        warranties, direct or implied, in this regard.{" "}
                      </li>
                      <li>
                        <span className="pr-20"> 15.3 </span>
                        Circle assumes no responsibility and shall not be liable
                        for any damage to, or viruses that may infect your
                        computer, equipment or other property on account of your
                        access the websites, apps or resources of third parties
                        or Service Providers.{" "}
                      </li>
                      <li>
                        <span className="pr-20"> 15.4 </span>In no event shall
                        Circle be liable for any injury, loss, claim, damages,
                        or any exemplary, punitive, direct, indirect, incidental
                        or consequential damages of any kind whether based in
                        contract, tort, strict liability, or otherwise as a
                        result of third parties or Service Providers’ websites,
                        apps or resources.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <span className="pr-20"> 16.</span>{" "}
                    <span className="font_bold">CLINICAL RISK</span>
                    <ul classname="list_unstyled">
                      <li>
                        <span className="pr-20"> 16.1</span> We do not exclude
                        or limit in any way our liability to you where it would
                        be unlawful to do so. This includes liability for death
                        or personal injury caused by our negligence or the
                        negligence of our employees, agents or subcontractors.
                      </li>
                      <li>
                        <span className="pr-20"> 16.2</span> Please note that
                        Circle is not a provider of healthcare services. Circle
                        Health, as referenced in clause 7, are a provider of
                        healthcare services.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <span className="pr-20"> 17. </span>
                    <span className="font_bold">ACCESS TO MYWAY</span>
                    <ul classname="list_unstyled">
                      <li>
                        <span className="pr-20"> 17.1 </span>From time to time,
                        you may be temporarily unable to access MyWay for the
                        following reasons:
                        <ul classname="list_unstyled">
                          <li>
                            <span className="pr-20"> 17.1.1 </span>
                            the MyWay digital platform is undergoing planned
                            maintenance;
                          </li>
                          <li>
                            <span className="pr-20"> 17.1.2 </span>
                            there are system outages which are outside of our
                            control;
                          </li>
                          <li>
                            <span className="pr-20"> 17.1.3 </span> we have
                            suspended your access to and use of MyWay in
                            accordance with clause 23.1 of these Terms; and/or
                          </li>
                          <li>
                            <span className="pr-20"> 17.1.4 </span>
                            we have otherwise deemed it reasonably necessary to
                            suspend your access to and use of MyWay, including,
                            for example, while a data security risk is being
                            investigated.
                          </li>
                        </ul>
                      </li>
                      <li>
                        <span className="pr-20"> 17.2</span> We will provide you
                        with reasonable notice of any planned maintenance
                        undertaken in accordance with clause 17.1.1. In all
                        other cases, we will provide you with updated
                        information as to when access to MyWay will resume
                        insofar as we are reasonably able to.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <span className="pr-20"> 18.</span>
                    <span className="font_bold">
                      NO RIGHTS FOR THIRD PARTIES
                    </span>
                    <ul classname="list_unstyled">
                      <li>
                        <span className="pr-20"> 18.1</span> These Terms do not
                        give rise to any rights under the Contracts (Rights of
                        Third Parties) Act 1999 to enforce any clause of these{" "}
                        Terms.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <span className="pr-20"> 19.</span>{" "}
                    <span className="font_bold">GOVERNING LAW </span>
                    <ul classname="list_unstyled">
                      <li>
                        <span className="pr-20"> 19.1 </span>These Terms, their
                        subject matter and formation (including any disputes and
                        claims) shall be governed and interpreted in accordance
                        with the laws of England and Wales. It is agreed that
                        the courts of England and Wales will have exclusive
                        jurisdiction. This clause 19.1 is subject only to clause
                        19.2.
                      </li>
                      <li>
                        <span className="pr-20"> 19.2 </span>It is agreed that
                        if you are a resident of Northern Ireland you may bring
                        proceedings in Northern Ireland, and if you are resident
                        of Scotland, you may bring proceedings in Scotland.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <span className="pr-20">20.</span>{" "}
                    <span className="font_bold">TRADE MARKS</span>
                    <ul classname="list_unstyled">
                      <li>
                        <span className="pr-20"> 20.1 </span>The display of any
                        trade mark on MyWay does not imply that any license has
                        been granted by its owner. No trade mark infringement is
                        intended and no suggestion is made that the owner of the
                        trade mark endorses our product or services.
                      </li>
                      <li>
                        <span className="pr-20"> 20.2 </span>Our display of a
                        trade mark does not constitute an endorsement or
                        approval by us of the trade mark owner, its website, app
                        or product and/or services.
                      </li>
                      <li>
                        <span className="pr-20"> 20.3</span> “MY Circle WAY”,
                        both the words and associated logo, is a UK registered
                        trade mark of Circle. You are not permitted to use this
                        trade mark without our approval, unless otherwise
                        permitted by these Terms or our Policies.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <span className="pr-20"> 21.</span>
                    <span className="font_bold">INDEMNITY</span>
                    <ul classname="list_unstyled">
                      <li>
                        <span className="pr-20"> 21.1 </span>You shall indemnify
                        and keep Circle indemnified against all actions, claims,
                        losses, costs (including all legal costs actually
                        incurred or otherwise), damages and expenses that are
                        suffered or incurred by us whether or not reasonable or
                        otherwise foreseeable or avoidable which arise directly
                        or indirectly from any breach or alleged breach by you
                        of any of these Terms.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <span className="pr-20"> 22. </span>
                    <span className="font_bold">SEVERABILITY</span>
                    <ul classname="list_unstyled">
                      <li>
                        <span className="pr-20"> 22.1 </span>If any part of
                        these Terms are for any reason declared invalid by a
                        court of competent jurisdiction, this will not affect
                        the validity of any remaining part of these Terms.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <span className="pr-20"> 23. </span>
                    <span className="font_bold">TERMINATION</span>
                    <ul classname="list_unstyled">
                      <li>
                        <span className="pr-20"> 23.1</span> We may terminate or
                        suspend your access to and use of MyWay by notice with
                        immediate effect for any of the following reasons:
                        <ul classname="list_unstyled">
                          <li>
                            <span className="pr-20"> 23.1.1</span> you do not
                            comply with these Terms;
                          </li>
                          <li>
                            <span className="pr-20"> 23.1.2 </span>
                            you have breached the Additional Terms set out in
                            clause 4.1, including for the avoidance of doubt by
                            failing to maintain payments related to your MyWay
                            membership and/or by any fraudulent use of MyWay;
                            and/or
                          </li>
                          <li>
                            <span className="pr-20"> 23.1.3</span> we believe
                            your conduct towards our staff, or those engaged or
                            employed by us or our Service Providers, is{" "}
                            disruptive, abusive, discriminatory, threatening or
                            otherwise intimidating, whether by physical, verbal,
                            digital means or otherwise.
                          </li>
                        </ul>
                      </li>
                      <li>
                        <span className="pr-20"> 23.2 </span>After termination,
                        we will have no further obligation to you to provide
                        access to and use of MyWay. If we end your rights to use{" "}
                        MyWay, you must stop all activities authorised by these
                        Terms.
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default TermsAndConditions;
