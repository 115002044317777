import React from 'react'
import logo from '../assets/images/logo.png';

const Header = () => {

    return (
        <header className='site-header'>
            <div className="container">
                <a href="/" target="_blank">
                    <img src={logo} className="My Way logo-class" alt="logo" />
                </a>
            </div>
        </header>
    )
}

export default Header