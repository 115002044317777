import React from "react";

import mainLogo from "../assets/mainLogo.svg";
import mainBanner from "../assets/images/homeshot-banner.png";
import whyMy from "../assets/images/why_my.png";
import healthcareNeed from "../assets/healthcare-need.png";
import checkMart from "../assets/check-mark.svg";
import ArrowWwhiteRight from "../assets/home-icon/arrow-whiteRight.svg";
import "./index.css";
import queryString from "query-string";
import { useState } from "react";
import Footer from "./footer";

const HomePage = () => {
  const [showFooter, setShowFooter] = useState(true);
  const parsed = queryString.parse(window.location.search);
  const utmParameters = JSON.stringify(parsed);
  localStorage.setItem("utmParameters", utmParameters);
  return (
    <>
      <div className="landing-first">
        <section className="landing-banner">
          <div className="putting-control">
            <div className="container">
              <div className="row">
                <div className="col-md-6 mt-5">
                  <img
                    src={mainLogo}
                    alt="logo"
                    className="img-fluid pt-2 banner-logo"
                  />
                  <h2 className="mt-4">
                    Putting you in control <br />
                    of your health
                  </h2>
                  <p className="mt-3">
                  Become a member of your local private hospital from as little as £19.99 per month. At Circle
                  Health Group, we have launched a brand-new health membership, giving you fast access to
                  specialist consultations, scans and tests at the UK’s largest private hospital group. You will
                  also have unlimited access to virtual GP appointments that can be booked anytime,
                  anywhere.
                  </p>
                  <button
                    className="btn btn-solid mt-5 mb-4 d-none d-md-block"
                    onClick={() => {
                      window.location =
                        "https://join.circlemyway.co.uk?parameter=" +
                        utmParameters;
                    }}
                  >
                    Get started now
                    <img src={ArrowWwhiteRight} alt="arrow-right" />
                  </button>
                  {/* <LoadingButton type="submit" className="csButton csButton-m">
                
              </LoadingButton> */}
                </div>
                <div className="col-md-6">
                  <div className="mobile-click text-center">
                    <div className="price-cricle">
                      <h2>£19.99</h2>
                      <p>per month*</p>
                    </div>
                    <img
                      src={mainBanner}
                      alt="banner"
                      className="img-fluid mx-auto d-md-none"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="speciality-list cricle-speciality-list">
              <ul>
                <li>
                  <img src={checkMart} alt="icon" className="img-fluid" />
                  Book GP and specialists online
                </li>
                <li>
                  <img src={checkMart} alt="icon" className="img-fluid" />
                  Face-2-Face specialist appointments
                  <a
                    href="#show-footer"
                    onClick={() => {
                      setShowFooter(true);
                    }}
                    style={{ color: "black" }}
                  >
                    **
                  </a>
                </li>
                <li>
                  <img src={checkMart} alt="icon" className="img-fluid" />
                  Diagnostic scans and tests included
                  <a
                    href="#show-footer"
                    onClick={() => {
                      setShowFooter(true);
                    }}
                    style={{ color: "black" }}
                  >
                    **
                  </a>
                </li>
                {/* <li>
                  <img src={checkMart} alt="icon" className="img-fluid" />
                  Discounted hospital treatment
                </li> */}
              </ul>
            </div>
          </div>
          <div className="container fast-access">
            <div className="row align-items-center">
              <div className="col-md-6">
                <button
                  className="btn btn-solid mt-4 mb-4 d-block d-md-none mx-auto"
                  onClick={() => {
                    window.location =
                      "https://join.circlemyway.co.uk?parameter=" +
                      utmParameters;
                  }}
                >
                  Get started now
                  <img src={ArrowWwhiteRight} alt="arrow-right" />
                </button>
                <div className="mobile-click text-center d-none d-md-block">
                  <img
                    src={mainBanner}
                    alt="banner"
                    className="img-fluid mx-auto"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <h4>Fast access to healthcare when you need it</h4>
                <p className="mt-3">
                MyWay is the UK’s low-cost health subscription that provides fast access toGPs, specialist
                doctors, scans and tests with the UK’s largest private hospital group.**{" "}
                </p>

                <p className="mt-3">
                As a member, you’ll get personalised advice from a GP as soon as you join, with fast access
                to a specialist consultant via a GP referral, after 30 days of membership.. If you do need
                treatment, you’ll get discounted, prioritised access to a Circle Health Group hospital.
                </p>
                <h5>
                  “No restrictions for pre-existing conditions, rapid access to
                  GP and specialist”
                </h5>
                <span>Consumer feedback 2022</span>
              </div>
            </div>
          </div>
          <div className="subscription-age">
            <div className="container">
              <div className="subs-agegroup">
                <h2>3 simple steps to specialist care</h2>
                <div className="simple-steps">
                  <div className="step-step">
                    <div className="xl-text">1</div>
                    <p>
                      <a href="Javascript:void(0)"> Sign up</a> to MyWay{" "}
                    </p>
                  </div>
                  <div className="step-step">
                    <div className="xl-text">2</div>
                    <p>
                      <a href="Javascript:void(0)"> Log in</a> to your MyWay
                      account anytime{" "}
                    </p>
                  </div>
                  <div className="step-step">
                    <div className="xl-text">3</div>
                    <p>
                      <a href="Javascript:void(0)"> 24/7 access</a> to
                      specialist and GP appointments
                    </p>
                  </div>
                </div>

                <button
                  className="btn btn-solid mt-2"
                  onClick={() => {
                    window.location =
                      "https://join.circlemyway.co.uk?parameter=" +
                      utmParameters;
                  }}
                >
                  Sign up today
                  <img src={ArrowWwhiteRight} alt="arrow-right" />
                </button>
              </div>
            </div>
          </div>
        </section>
        <section className="what-included">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <table className="table included-table">
                  <tr>
                    <th>Whats included</th>
                    <th>
                      <div className="blue-head">
                        <small> from </small>
                        <br />
                        Day 1
                      </div>
                    </th>
                    <th>
                      <div className="blue-head">
                        <small>after</small>
                        <br /> 30 days
                      </div>
                    </th>
                  </tr>
                  <tr>
                    <td>Access to a specialist consultant</td>
                    <td></td>
                    <td>
                      <img src={checkMart} alt="icon" className="img-fluid" />
                    </td>
                  </tr>
                  <tr>
                    <td>Tests or scans to confirm your diagnosis</td>
                    <td></td>
                    <td>
                      <img src={checkMart} alt="icon" className="img-fluid" />
                    </td>
                  </tr>
                  <tr>
                    <td>Unlimited virtual GP appointments</td>
                    <td>
                      <img src={checkMart} alt="icon" className="img-fluid" />
                    </td>
                    <td>
                      <img src={checkMart} alt="icon" className="img-fluid" />
                    </td>
                  </tr>
                  <tr>
                    <td>Online symptom assessment checker</td>
                    <td>
                      <img src={checkMart} alt="icon" className="img-fluid" />
                    </td>
                    <td>
                      <img src={checkMart} alt="icon" className="img-fluid" />
                    </td>
                  </tr>
                  <tr>
                    <td>Self-help virtual physio</td>
                    <td>
                      <img src={checkMart} alt="icon" className="img-fluid" />
                    </td>
                    <td>
                      <img src={checkMart} alt="icon" className="img-fluid" />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      10% discount for hospital treatment at Circle Health Group
                      hospitals
                      <a
                        href="#show-footer"
                        onClick={() => {
                          setShowFooter(true);
                        }}
                        style={{ color: "black", padding: 0 }}
                      >
                        ***
                      </a>
                    </td>
                    <td>
                      <img src={checkMart} alt="icon" className="img-fluid" />
                    </td>
                    <td>
                      <img src={checkMart} alt="icon" className="img-fluid" />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      10% discount for face-2-face appointments with our
                      physiotherapists
                    </td>
                    <td>
                      <img src={checkMart} alt="icon" className="img-fluid" />
                    </td>
                    <td>
                      <img src={checkMart} alt="icon" className="img-fluid" />
                    </td>
                  </tr>
                </table>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="subscription-age pb-0">
                  <div className="subs-agegroup">
                    <h2>
                      <a
                        href="#show-footer"
                        onClick={() => {
                          setShowFooter(true);
                        }}
                        style={{ color: "black" }}
                      >
                        *
                      </a>
                      Monthly subscription price depending on your age
                    </h2>
                    <div className="age-box">
                      <ul className="font-lato-semibold">
                        <li>
                          <span style={{ paddingLeft: "18px", color: "#000" }}>
                            Age group
                          </span>
                        </li>
                        <li>18 - 39</li>
                        <li>40 - 44</li>
                        <li>45 - 54</li>
                        <li>55 - 59</li>
                        <li>60 - 69</li>
                      </ul>
                      <ul className="font-lato-medium">
                        <li>
                          <span style={{ paddingLeft: "18px" }}>
                            Monthly cost
                          </span>
                        </li>
                        <li>£ 19.99</li>
                        <li>£ 22.50</li>
                        <li>£ 26.99</li>
                        <li>£ 29.50</li>
                        <li>£ 34.50</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row care-steps">
              <div className="col-md-7">
                <h2>Why MyWay?</h2>
                <p className="mt-3">
                MyWay guarantees rapid access to specialists, cutting-edge equipment, fast
                diagnosis and discounted treatment. From as little as £19.99 a month* you will have an
                annual allowance of up to £10,000 for consultations with a specialist consultant and any
                eligible tests and scans you need to get a diagnosis. That's less than £250 a year, the
                average cost of one appointment with a specialist consultant.
                </p>

                <p className="mt-3">
                As the largest private hospital group in the UK you can rely on our team to work together to
                ensure you get the specialist healthcare advice and support you need.
                </p>

                <p className="mt-3">
                You’ll get instant access to book appointments with the healthcare professional you need at
                a time to suit you.
                </p>

                <p className="mt-3">
                And of course you will see an expert specialist and have your scans and tests in one of Circle
                Health Group’s award-winning hospitals.
                </p>
              </div>
              <div className="col-md-5 mt-3">
                <img src={whyMy} className="img-fluid" />
              </div>
            </div>
          </div>
        </section>
        <section className="healthcare-need text-center bg-white">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <h2 className="mt-0">
                  Join MyWay today and simplify <br /> your healthcare needs
                </h2>
                <p>Are you ready to take control of your health?</p>
                <button
                  className="btn btn-solid mt-4 mb-5"
                  onClick={() => {
                    window.location =
                      "https://join.circlemyway.co.uk?parameter=" +
                      utmParameters;
                  }}
                >
                  Get started today
                  <img
                    src={ArrowWwhiteRight}
                    className="mx-2 img-fluid mb-0"
                    alt=""
                  />
                </button>
                {/* <LoadingButton
                type="submit"
                className="csButton csButton-m mt-4 mb-5"
              >
                Get started today
                <i className="fa fa-arrow-right mx-2" aria-hidden="true"></i>
              </LoadingButton> */}
                <img
                  src={healthcareNeed}
                  alt="banner"
                  className="img-fluid mx-auto d-block"
                />
              </div>
            </div>
          </div>
        </section>
        <section className="information-faq light-blue-bg">
          <div className="container">
            <div className="row">
              <div className="col-md-10 mx-auto">
                <h2 className="text-center">More information</h2>
                <div className="accordion informative" id="accordionExample">
                  <div className="accordion-item">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                      id="headingOne"
                    >
                      Why MyWay?
                    </button>
                    <div
                      id="collapseOne"
                      className="accordion-collapse collapse hide"
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <p>
                        MyWay is an annual healthcare subscription that provides fast access to GPs, specialists,
                        scans and tests at no extra cost with Circle Health Group, the UK’s largest private hospital
                        group.
                        </p>
                        <p className="mt-3">
                        The simple sign-up process provides instant access to your own digital portal. Through the
                        portal you can access advice, support and book healthcare professional appointments at a
                        time to suit you. There are no restrictions related to pre-existing medical conditions and no
                        authorisations required to join. You can also use all the eligible benefits as many times as
                        needed up to the value of £10,000 per year.{" "}
                        </p>
                        <p className="mt-3">
                        As soon as you become a MyWay member you will be able to get personalised advice and
                        care from the virtual GP service, symptom checker and self help for bones, joints and
                        muscles.
                        </p>
                        {/* <p>
                          After 30 days of being a member you can gain a
                          referral to see a MyWay consultant partner, book
                          specialist face-2-face appointments and get scans and
                          tests as requested by your MyWay consultant. You have
                          the reassurance that if you need to use these
                          services, you’ll get fast access to appointments which
                          are included in your monthly subscription fee. And of
                          course you will see one of our expert specialists or
                          have your scans and tests in one of our award-winning
                          hospitals. All of this is included in your monthly
                          subscription fee**, no approvals or authorisations are
                          required and there are no waiting lists.{" "}
                        </p> */}
                        <p className="mt-3">
                          After 30 days of being a member you can book a face-to-face consultation with a specialist
                          consultant at a Circle Health Group Hospital providing you have a referral from a GP or other
                          specialist referrer and book scans and tests, as requested by your specialist.
                          Our team work together to ensure you get the specialist healthcare advice and support you
                          need. If you do need treatment, you’ll get discounted, prioritised access to a Circle Health
                          Group hospital.
                        </p>
                        <p className="mt-3">
                          Access your MyWay services 24/7 from your mobile, tablet or computer – You’re in control.{" "}
                        </p>
                        {/* <p>
                          Access your MyWay services 24/7 from your mobile,
                          tablet or computer – You’re in control.{" "}
                        </p> */}
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      id="headingTwo"
                      aria-controls="collapseTwo"
                    >
                      Can I use all the benefits straight away?
                    </button>
                    <div
                      id="collapseTwo"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingTwo"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <p className="mb-3">
                        MyWay is an annual healthcare subscription that has been designed to provide the ongoing
                        reassurance to members that they can access healthcare services as and when they need
                        them. Some benefits are available as soon as you sign up, for example access to virtual GP
                        appointments, for other benefits you need to have been a member for 30 days before you
                        can access{" "}
                        </p>
                        <strong>Immediate access: </strong>
                        <ul className="px-3">
                          <li>Digital portal </li>
                          <li>Virtual GP </li>
                          <li>Symptom checker and guidance </li>
                          <li>
                          Self-help and exercises for bone, muscle and joint conditions
                          </li>
                          <li>Discounted hospital treatment</li>
                          <li>Discounted face-2-face physiotherapy</li>
                        </ul>
                        <strong className="mt-3 d-block">
                        After 30 days of membership:{" "}
                        </strong>
                        <ul className="px-3 mb-3">
                          <li>
                            Gain a referral to see a MyWay specialist consultant{" "}
                          </li>
                          <li>
                            Book a face-to-face appointment with a specialis
                            <a
                              href="#show-footer"
                              onClick={() => {
                                setShowFooter(true);
                              }}
                              style={{ color: "black" }}
                            >
                              **
                            </a>
                          </li>
                          <li>
                            Scans and tests required to diagnose your condition
                            <a
                              href="#show-footer"
                              onClick={() => {
                                setShowFooter(true);
                              }}
                              style={{ color: "black" }}
                            >
                              **
                            </a>{" "}
                          </li>
                        </ul>
                        <p>
                          If you already have a GP referral and an immediate clinical need,you can utilise the 10%
                          discount on hospital treatment or physiotherapy which is available immediately.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                      id="headingThree"
                    >
                      MyWay services
                    </button>
                    <div
                      id="collapseThree"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingThree"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        You can use your MyWay Digital portal to access
                        self-help, professional support and specialist
                        consultants to support your healthcare. Whether it’s a
                        common cold, sprained ankle or something that needs a
                        more thorough investigation, you have online access to
                        healthcare services that can support you when you need
                        it, with the reassurance that MyWay membership can help you get diagnosed, fast.
                        {/* <button className="accordion-button-myway-inside">
                          Self help
                        </button> */}
                        <div className="accordion-button-myway-inside-body">
                          <p className="mt-3">
                            Access the symptom assessment checker to get advice on how you can manage your
                            symptoms at home or if you need to seek professional medical support.{" "}
                          </p>
                          <p className="mt-3">
                             Use the virtual physio and online assessment for bone, muscle and joint conditions to gain
                             self-care advice and access to exercises that can help relieve your symptoms. All with the
                             comfort that you will be advised when you should be seeking medical care based on your
                             personal symptoms and circumstances.{" "}
                          </p>
                        </div>
                        <button className="accordion-button-myway-inside">
                          GP services.
                        </button>
                        <div className="accordion-button-myway-inside-body">
                          <p>
                          MyWay allows you to book a telephone or video appointment quickly and easily with one of
                          over 100 experienced GPs. Appointment times are from Monday – Sunday, 8am – 10pm,
                          with slots available within just 24 hours.{" "}
                          </p>
                          <p className="mt-3">
                            Booking your appointment is a simple process thanks to the easy-to-navigate system.
                            Whether you need help with a minor health-related query, or an appointment to discuss an
                            ongoing issue, the GP you speak with is there to offer advice, practical information, and
                            reassurance when you need it.{" "}
                          </p>
                          <p className="mt-3">
                            Your appointment will normally last around 15 minutes, but if you need longer, that’s no
                            problem. Any prescriptions will need to be paid for separately and are not covered by your
                            MyWay membership
                            <a
                              href="#show-footer"
                              onClick={() => {
                                setShowFooter(true);
                              }}
                              style={{ color: "black" }}
                            >
                              **
                            </a>
                          </p>
                          <p className="mt-3">
                          If further investigations are needed, after 30 days of being a MyWay member, your MyWay
                          GP can provide a referral to see a MyWay specialist consultant in one of our award-winning
                          hospitals. All of this is included within your MyWay membership.{" "}
                          </p>
                          <p className="mt-3">
                          You can also rest assured that we’ll keep your GP practice informed, guaranteeing your
                          medical records are consistently up to date.{" "}
                          </p>
                        </div>
                        <button className="accordion-button-myway-inside">
                          Private hospital diagnosis
                        </button>
                        <div className="accordion-button-myway-inside-body">
                          <p>
                            After 30 days of being a MyWay member and once you have a GP referral, you can book a
                            face-to-face appointment through the Digital portal to see a specialist consultant in one of
                            Circle Health Groups hospitals. With over 50 hospitals around the UK, you shouldn’t have to
                            travel far to get the diagnosis you need. Appointment availability varies at each hospital,but
                            a consultation with a specialist consultant will normally be available within 48 hours.{" "}
                          </p>
                          <p className="mt-3">
                           Should your chosen specialist require tests or scans to confirm your diagnosis you don’t have
                           to worry as these are also included as part of your subscription.
                          </p>
                          <p className="mt-3">
                            Eligible consultations, tests and scans are included up to the value of £10,000 per year.{" "}
                          </p>
                        </div>
                        <button className="accordion-button-myway-inside">
                          Private hospital treatment
                        </button>
                        <div className="accordion-button-myway-inside-body">
                          <p>
                            If you need further treatment or surgery, you will receive prioritised access to one of our
                            private hospitals. You’ll also receive a 10% discount on hospital fees{" "}
                          </p>
                          <p className="mt-3">
                            MyWay members also receive a 10% discount for any face-to-face physiotherapy with one of
                            our experienced Circle Health Group physiotherapists.{" "}
                          </p>
                        </div>
                        <button className="accordion-button-myway-inside">
                           Want to learn more about MyWay membership?
                        </button>
                        <div className="accordion-button-myway-inside-body">
                          <p>
                           Our short video shows how easy it is for MyWay members to book specialist consultant and
                           GP appointments, and more.
                          </p>
                          <p className="text-center m-5">
                            <video controls>
                              <source src="/assets/Circle Health_Explain.mp4" type="video/mp4" />
                              <source src="/assets/Circle-Health_Explain.ogg" type="video/ogg" />
                               Your browser does not support HTML video.
                            </video>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseeight"
                      aria-expanded="false"
                      aria-controls="collapseeight"
                      id="headingeight"
                    >
                      About Circle Health Group
                    </button>
                    <div
                      id="collapseeight"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingeight"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <p>
                          Circle Health Group has over 50 award-winning private
                          hospitals across England, Scotland and Wales. 90% of
                          adults in the UK live within 90 minutes of a Circle
                          Health Group hospital so you won’t have to travel far
                          to receive the care and support you need.{" "}
                        </p>
                        <p className="mt-3">
                          All our hospitals provide high quality care and a
                          comprehensive range of services and facilities. We use
                          the latest medical equipment and technology to help
                          diagnose and treat your health concerns as quickly and
                          expertly as possible. Our focus on combining clinical
                          excellence with the compassion of our staff and the
                          comfort of our surroundings allows us to provide
                          exceptional levels of care.{" "}
                        </p>
                        <p className="mt-3">
                          We work with highly skilled consultant specialists who
                          work alongside multidisciplinary teams of
                          physiotherapists, radiographers, nurses, healthcare
                          assistants, and more. Let our expert team of
                          healthcare professionals and partners look after you
                          when you need us.{" "}
                        </p>
                        <p className="mt-3">
                          You can trust the UK’s largest network of private
                          hospitals to help you manage your health and
                          wellbeing.{" "}
                        </p>
                        <p className="mt-3">
                          Find our more about Circle Health Group, our services
                          and specialists and location of our hospitals{" "}
                          <a
                            href="https://www.circlehealthgroup.co.uk/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            here.
                          </a>{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                {showFooter && (
                  <div className="mt-5" id="show-footer">
                    <span className="mt-5">
                    **Eligible consultations, scans and tests required to diagnose your condition are included
                    following a referral to a specialist, from the virtual GP, your own GP or other medical
                    specialist, dated at least 30 days after your subscription started, to the value of £10k per
                    year. Consultations, scans and tests under MyWay membership are not available at Bury St Edmunds Hospital, Southend Private
                    Hospital, Werndale Hospital or Woodlands Hospital. MyWay includes access to a wide range of Circle Health
                    Group specialties, however it does not include genetic tests, treatment related to maternity,
                    pregnancy, fertility, reproductive health, or related to communicable diseases, also known as
                    infectious diseases
                    </span>
                    <br />
                    <br />
                    <span className="mt-5">
                    ***10% discount for hospital treatment is applicable to the hospital fees only. No discount is
                    provided on the consultant's fees or the anaesthetist's fees associated with your surgery or
                    treatment.
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
        <Footer />
        {/* <div className="landing-footer">
          <p>
            © 2023 MyWay Ltd &nbsp; •<a href="/#"> FAQs</a> •
            <a href="/#"> Terms & Conditions</a>
          </p>
        </div> */}
      </div>
    </>
  );
};

export default HomePage;
