import React from "react";
import Header from "../header";
import "../TermsAndConditions/index.css";
import Footer from "../../home/footer";
import "./index.css"
const PrivacyPolicy = () => {
  return (
    <div className="privacy-p">
      <div className="fixed-header">
        <Header />
        <div className="container">
          <div className="main-inner m-b-20">
            <h3>Circle MyWay Privacy Overview</h3>
          </div>
        </div>
      </div>
      <div className="main-content">
        <div className="container">
          <div className="tabs-body">
            <div className="tab-content">
              <div className="tab-left">
                <p>
                  This Privacy Overview sets out important information about who
                  may collect and process your personal data when you use the
                  Circle MyWay progressive web app <b>(“MyWay”).</b> This is
                  designed to be a user-friendly overview of how your personal
                  data will be collected and used within MyWay, and not a formal
                  Privacy Notice.{" "}
                </p>
                <p>
                  Please read this Privacy Overview carefully and, where
                  relevant, follow the links to the Privacy Notices of the other
                  organisations comprising the MyWay offering. If you have any
                  questions please contact us using the details provided below.
                </p>
                <ul className="list-unstyled">
                  <li>
                    <b>
                      <h3 style={{ padding: "15px 0px" }}>
                        <span style={{ paddingLeft: "20px" }}>
                          1.
                          <span style={{ paddingLeft: "10px" }}>
                            {" "}
                            Background
                          </span>
                        </span>
                      </h3>{" "}
                    </b>
                  </li>
                  <p>
                    MyWay is a product of Circle Health MyWay Limited (
                    <b>“Circle”</b>, <b>“we”</b>,<b>“us”</b> and <b>“our”</b>).
                    We have partnered with MediPartner Limited
                    <b>(“MediPartner”)</b> to deliver MyWay.
                  </p>
                  <p>
                    MyWay is a self-paid subscription service. It provides users
                    with access to a digital platform <b>(“Platform”)</b>,
                    through which they can access the following benefits{" "}
                    <b>(“Benefits”)</b> from third party organisations,
                    including our affiliates <b>(“Service Providers”)</b>:
                  </p>
                  <ul style={{ listStyle: "disc" }}>
                    <li style={{ paddingBottom: "10px" }}>
                      <b>health services,</b> namely virtual GP consultations
                      and online physiotherapy questionnaire & self-care
                      services;
                    </li>
                    <li style={{ paddingBottom: "10px" }}>
                      <b> insurance cover</b> for the diagnosis of health
                      conditions; and{" "}
                    </li>
                    <li>
                      <b> membership discounts,</b> access to discounted medical
                      treatment and care at qualifying hospitals and facilities
                      within the UK.{" "}
                    </li>
                  </ul>
                  <p>
                    Circle is responsible for the overall coordination of MyWay,
                    and has engaged MediPartner to operate the Platform and
                    provide the electronic data services required to facilitate
                    it, including sign-up and payment collection.
                  </p>
                  <p>
                    Please note that neither Circle nor MediPartner are Service
                    Providers themselves. Rather, by Circle coordinating MyWay
                    and MediPartner operating the Platform, they facilitate
                    users’ access to Service Providers, from whom users can
                    directly seek Benefits.
                  </p>
                  <p>
                    Although Circle is not a Service Provider insofar as the
                    Benefits under the Platform are concerned, the{" "}
                    <b>membership discounts </b>
                    accessible to MyWay users are within Circle Health Group{" "}
                    <b>(“CHG”) </b>
                    hospitals and facilities. CHG is a private healthcare
                    provider and one of our affiliates. In the event you access
                    these <b>membership discounts,</b> either by way of a
                    referral from a Service Provider or an independent referral,
                    please follow the link below to CHG’s Privacy Notice to find
                    out further details about how CHG uses your personal data.
                  </p>

                  <li>
                    <b>
                      <h3 style={{ padding: "15px 0px" }}>
                        <span style={{ paddingLeft: "20px" }}>
                          2.
                          <span style={{ paddingLeft: "10px" }}>
                            {" "}
                            Service Providers’ websites, apps or resources
                          </span>
                        </span>
                      </h3>
                    </b>
                  </li>
                  <p>
                    It is important you are aware that we do not have any
                    control over the content of our Service Providers’ websites,
                    apps or resources, or the way in which our Service Providers
                    collect and process personal data. Service Providers may,
                    for example, allow third parties to collect and process your
                    personal data. Circle makes no representations nor gives any
                    warranties, direct or implied, in this regard.
                  </p>
                  <p>
                    Each one of our Service Providers has its own Privacy Policy
                    which sets out the way in which it may collect and process
                    your personal data should you choose to engage with them. It
                    is therefore important that you read the Privacy Policy of
                    any Service Provider you seek Benefits from and familiarise
                    yourself with its content. For ease of reference, we have
                    set these out below:
                  </p>
                  <p>
                    For the avoidance of doubt, any question, concern or
                    complaint you have arising from a Service Provider’s
                    collection or use of your personal data, or your legal
                    rights in respect of your personal data, ought to be
                    directed toward that Service Provider.
                  </p>
                  <table>
                    <tr>
                      <th>Service Provider</th>
                      <th>Terms of Use </th>
                      <th>Privacy Policy</th>
                    </tr>
                    <tr>
                      <td>Circle Health Group</td>
                      <td>
                        As noted above, CHG is a private healthcare provider and
                        one of our affiliates, and has been engaged to provide
                        diagnostic scans and tests, and discounted medical
                        treatment and care.{" "}
                      </td>
                      <td>
                        <a
                          href="https://www.circlehealthgroup.co.uk/privacy-notices"
                          target="_blank"
                          rel="noreferrer"
                        >
                          https://www.circlehealthgroup.co.uk/privacy-notices
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>HealthHero</td>
                      <td>
                        Health Hero is a provider of a range of virtual primary
                        care services, and has been engaged to provide virtual
                        GP services through the Platform.
                      </td>
                      <td>
                        <a
                          href="https://www.healthhero.com/privacy-policy"
                          target="_blank"
                          rel="noreferrer"
                        >
                          https://www.healthhero.com/privacy-policy
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>MediPartner</td>

                      <td>
                        MediPartner has been engaged to build and operate the
                        Platform, and manage complaints & feedback related to
                        the Platform.{" "}
                      </td>
                      <td>
                        <a
                          href="https://medipartner.co.uk/medipartner-privacy-policy/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          https://medipartner.co.uk/medipartner-privacy-policy/
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>Physitrack</td>
                      <td>
                        Physitrack is a provider of online physiotherapy
                        questionnaire and self-care services, and has been
                        engaged to provide the same through the Platform.
                      </td>
                      <td>
                        <a
                          href="https://www.physitrack.co.uk/legal/privacy"
                          target="_blank"
                          rel="noreferrer"
                        >
                          https://www.physitrack.co.uk/legal/privacy
                        </a>{" "}
                      </td>
                    </tr>
                  </table>
                  <p>
                    For the avoidance of doubt, any question, concern or
                    complaint you have arising from a Service Provider’s
                    collection or use of your personal data, or your legal
                    rights in respect of your personal data, ought to be
                    directed toward that Service Provider.
                  </p>
                  <li>
                    <b>
                      <h3 style={{ padding: "15px 0px" }}>
                        <span style={{ paddingLeft: "20px" }}>
                          3.{" "}
                          <span style={{ paddingLeft: "10px" }}>
                            Collection and provision of personal data
                          </span>
                        </span>
                      </h3>
                    </b>
                  </li>
                  <p>
                    When users purchase a subscription to MyWay, they are at a
                    minimum required to provide certain items of personal data
                    to support MyWay’s essential functionality. This includes,
                    for example, demographic information and contact
                    information.
                  </p>
                  <p>
                    This personal data is held by MediPartner on the Platform.
                    Further specific information about what personal data
                    MediPartner collects and the purposes for which it will be
                    used is accessible through its Privacy Notice here:
                    <a
                      href="https://medipartner.co.uk/medipartner-privacy-policy"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {" "}
                      https://medipartner.co.uk/medipartner-privacy-policy.
                    </a>
                  </p>
                  <p>
                    It is important you are aware that in order to offer
                    user-friendly access to the Benefits, to allow our users to
                    book appointments and to ease the referral process in the
                    event users’ require further medical treatment and care, in
                    some circumstances MediPartner will need to share personal
                    data with our Service Providers. This information is
                    detailed below:
                  </p>
                  <table>
                    <tr>
                      <th>Service Provider</th>
                      <th>Personal data shared</th>
                      <th>Reason</th>
                    </tr>
                    <tr>
                      <td>Circle Health Group</td>
                      <td>
                        Should you have a referral to see a MyWay specialist
                        consultant and choose to book an appointment via MyWay,
                        CHG will receive demographic and contact information
                        about you from MediPartner. The personal data that will
                        be shared with CHG will include: name, date of birth,
                        contact information including postcode and telephone
                        number, your MyWay membership number, whether or not you
                        have a referral letter and your chosen specialist.
                      </td>
                      <td>
                        The personal data that is shared with CHG is essential
                        to register you as a patient at your chosen hospital and
                        therefore set up your consultation appointment.
                      </td>
                    </tr>
                    <tr>
                      <td>HealthHero</td>
                      <td>
                        HealthHero will receive initial limited demographic
                        details (name, date of birth and postcode) about you
                        from MediPartner, and thereafter will hold and manage
                        all personal data about you (including information
                        concerning your health) within its own record management
                        systems.
                      </td>
                      <td>
                        <p>
                          The personal data that is shared with HealthHero is to
                          ensure that both whilst completing the symptom checker
                          and booking your GP consultation, HealthHero have the
                          basic information needed to provide clinically-led
                          feedback.{" "}
                        </p>
                        <p>
                          Your postcode is shared to ensure that your online GP
                          is aware of your whereabouts, in case of emergency and
                          they need to request emergency service help for you.
                          This information may be overwritten by you at the time
                          of making your booking.{" "}
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>MediPartner</td>

                      <td>
                        MediPartner will liaise with the relevant Service
                        Provider as necessary in order to respond to complaints
                        or feedback.{" "}
                      </td>
                      <td>
                        <p>
                          As operator of the Platform, Medipartner hold the data
                          to ensure effective running of the Platform.
                        </p>
                        <p>
                          They will share only essential personal data with the
                          Service Providers to ensure effective running of the
                          Platform and to ensure that you receive the most
                          comprehensive and relevant responses to any complaints
                          or feedback you provide.
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>Physitrack</td>
                      <td>
                        Physitrack collect the personal data that you provide
                        when using the “My Physio” services. They collect your
                        answers to the Musculoskeletal Questionnaire, as well as
                        the outcome that is generated, based on your answers.
                      </td>
                      <td>
                        <p>
                          This information will be used for triaging your
                          appropriateness for Physitrack’s services.
                        </p>
                        <p>
                          Physitrack will also use this information to ensure
                          that the right exercises are prescribed to you, where
                          this is relevant to the outcome of your questionnaire.
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td> Trust in Health</td>
                      <td>
                        Should you want to make a claim and book a specialist
                        appointment at a CHG hospital, Trust in Health will
                        complete the digital authorisation process. To do this,
                        Trust in Health will receive your initial demographic
                        data, including your name and date of birth. They will
                        also receive your MyWay membership number, whether you
                        have a GP referral and the date of this referral.
                        <p>
                          Once you have booked a consultation, Trust in Health
                          will receive confirmation of your appointment,
                          including your chosen consultant as well as the date
                          and time of your appointment.
                        </p>
                        <p>
                          Following your consultation, Trust in Health will
                          receive information about any diagnostic scans or
                          tests that it may have been clinically necessary for
                          your chosen consultant in to order or carry out. Trust
                          in Health will not receive any information relating to
                          the outcomes of the results of any such scans or
                          tests.
                        </p>
                      </td>
                      <td>
                        <p>
                          This information is used to determine whether you
                          qualify for the requested consultation/ scans/ tests
                          and whether your MyWay membership covers these costs,
                          all of which is necessary to provide you with an
                          authorisation for your request.
                        </p>
                        <p>
                          Should you require tests/ scans, Trust in Health
                          requires this information to ensure that your annual
                          allowance is updated accurately and is reflective of
                          your usage of the MyWay services.
                        </p>
                      </td>
                    </tr>

                    <tr>
                      <td>Health Pulse Services</td>
                      <td>
                        Health Pulse Services act on behalf of MyWay, to work
                        with an insurer to ensure that any claims you make as a
                        MyWay member are covered.
                        <p>
                          Health Pulse Services receive demographic data, which
                          includes your name, date of birth, address. Health
                          Pulse Services also receives data about your MyWay
                          membership including your MyWay membership number, and
                          the expiry/ renewal date of your MyWay membership.
                        </p>
                      </td>
                      <td>
                        This personal data is used to set up your profile and
                        ensure it is covered by the insurer. Your date of birth
                        is used to confirm the monthly/ annual cost of your
                        MyWay membership.
                      </td>
                    </tr>
                    <tr>
                      <td>Fortrus</td>
                      <td>
                        Fortrus collects your name, postcode and your
                        authorisation number for any specialist consultation you
                        book.
                      </td>
                      <td>
                        Fortrus collect this personal data to ensure that you
                        are able to make an online booking for a specialist
                        consultation. Fortrus uses your postcode to ensure that
                        the specialists closest to your address appear first and
                        collect your authorisation code to confirm that your
                        claim for the consultation has been approved.
                      </td>
                    </tr>
                  </table>
                  <li>
                    <b>
                      <h3 style={{ padding: "15px 0px" }}>
                        <span style={{ paddingLeft: "20px" }}>
                          4.
                          <span style={{ paddingLeft: "10px" }}>
                            {" "}
                            Contact details
                          </span>
                        </span>
                      </h3>
                    </b>
                    <p>
                      Please contact our Head of Information Governance or Data
                      Protection Officer if you have any questions about the
                      information contained within this Privacy Overview. They
                      can each be contacted using the details below:
                    </p>

                    <ul style={{ listStyle: "disc" }}>
                      <li>
                        Email: DataProtectionOfficer@circlehealthgroup.co.uk
                      </li>
                      <li>
                        Post: Circle Health Group, 1st Floor, 30 Cannon Street,
                        London, EC4M 6XH
                      </li>
                    </ul>
                  </li>
                  <p style={{ paddingBottom: "20px" }}>
                    Lastly, please note that because we update this Privacy
                    Overview from time to time, we advise you to review this
                    page for the latest version. This Privacy Overview was last
                    updated on 10 March 2023.
                  </p>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
