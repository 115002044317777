import React from "react";
import { Link } from "react-router-dom";
import logo from "../assets/images/supportedBy.svg";
// import cookiesPolicy from"../assets/Cookie Policy 10032023.docx"
// import termsandConditions from "../assets/Terms of Use 07032023.pdf"
// import privicyPolicy from "../assets/Privacy Overview 1003023.docx"

const Footer = () => {
  return (
    <footer className="site-footer">
      <div className="container">
        <div className="footer-inner">
          <ul className="footer-as">
            <li>
              <Link
                to="/terms-and-condition"
                target="_blank"
              >
                Terms &amp; Conditions
              </Link>
            </li>
            <li>
              <Link
                to="/privacy-policy"
                target="_blank"
              >
                Privacy Policy
              </Link>
            </li>
            <li>
              <Link
                to="/cookie-policy"
                target="_blank"
              >
                Cookie Policy
              </Link>
            </li>
          </ul>
          <div className="footer-bottom">
            <div className="footer-left">
              {/* <p>Copyright 2022 Health Pulse Services Limited.</p> */}
              <p className="m-b-10">
                Health Pulse Services Ltd is authorised and regulated by the Financial Conduct Authority (FCA) number FRN 943749, Company No. 12342341.
                <br />
                Registered office: Westhill House, 1st Floor, 2b Devonshire Road, Bexleyheath, DA6 8DS
                <br />
                MyWay is offered as a services and insurance plan. 
              </p>
              <p className="m-b-10">
                <b>For membership commencing on or before 31st December 2023:</b> The insurance is underwritten by Novus Underwriting Limited on behalf of Helvetia Global Solutions Limited. Registered Office: Aeulestrasse 60 (2. Stock) 9490 Vaduz, Liechtenstein.
              </p>
              <p>
                <b>For membership commencing on or after 1st January 2024:</b> The insurance is underwritten by Novus Underwriting Limited on behalf of Millennium Insurance Company Limited. Principal Office: PO Box 1314, 13 Ragged Staff Wharf, Queensway Quay, Gibraltar, GX11 1AA. Novus Underwriting Limited is registered in England and Wales under Company No. 10844265, with its registered office address at 4th Floor, 34 Lime Street, London EC3M 7AT. Novus Underwriting Limited is an appointed representative of Consilium Insurance Brokers Ltd, which is authorised and regulated by the Financial Conduct Authority. Firm Reference No. 306080.
              </p>
            </div>
            <div className="footer-right">
              <Link to="/" target="_blank">
                <img src={logo} className="supportedby" alt="logo" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
