import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import TagManager from "react-gtm-module";

const tagManagerArgs = {
  gtmId: "GTM-59TV8P2",
};
const tagManagerArgs1 = {
  gtmId: "GTM-TFMN7KT",
};
const tagManagerArgs2 = {
  gtmId: "GTM-WDQZ8HM",
};


TagManager.initialize(tagManagerArgs);
TagManager.initialize(tagManagerArgs1);
TagManager.initialize(tagManagerArgs2);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
