// import logo from "./logo.svg";
import "./App.css";
// import LandingPage from "./landing-page";
import HomePage from "./home";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import TermsAndConditions from "./components/TermsAndConditions";
import PrivacyPolicy from "./components/PrivacyPolicy";
import CookiePolicy from "./components/CookiePolicy";
import { MissingRoute } from "./components/MissingRoutes";

function App() {
  return (
    <main>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/terms-and-condition" element={<TermsAndConditions />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/cookie-policy" element={<CookiePolicy />} />
          <Route path="*" element={<MissingRoute />} />
        </Routes>
      </BrowserRouter>
    </main>
  );
}

export default App;
